<template>
 <ed-upload :key="$root.$session.versao"/>
</template>

<script>
import { EdTable, EdModal } from "@/components/common/form";
import EdUpload from "@/components/configuracoes/upload/index";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable, EdModal,EdUpload },
  mounted() {
    this.getArquivo();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      bind:{
        boolExibirCadastro:false
      },
      formData:{},
      arrayHeaders: [
        {
          text: "Nome",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Endereço",
          sortable: true,
          value: "strUrl",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros: {},
    };
  },
  methods: {
    onRegister() {
      this.bind.boolExibirCadastro = !this.bind.boolExibirCadastro 
    },

    onEdit(item){
      this.formData = Object.assign(item,{})
      this.bind.boolExibirCadastro = !this.bind.boolExibirCadastro 
    },

    getArquivo() {
      this.bind.boolExibirCadastro = false;
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Common/Arquivo", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objModel) => {

              let strImagem = ''

              if(objModel.strTipo.indexOf('image') != -1){
                strImagem = '<img witdh="100" src="'+objModel.strUrl+'"/><br>'
              }
              let item = {
                intId: objModel.intId,
                strNome: strImagem+objModel.strNome,
                strUrl:objModel.strUrl,
                _item: objModel,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
