<template>
  <div>
    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent"
    >
      <ed-input-file
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        v-model="formData"
        name="strConteudo"
        label="Selecione um arquivo"
        required
        rules="required|fileSize:1000"
        accept="image/png, image/jpeg, image/bmp"
      />
      <ed-alert type="warning" title="Atenção" class="text-left mt-5">Utilize somente imagens do tipo png, jpg com limite de 1MB de tamanho</ed-alert>
    </ed-form>
  </div>
</template>

<script>
import { EdForm, EdAlert, EdInputFile } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intId: {
      type: [Number, String],
      required: false,
      default: 0,
    },
  },
  components: {
    EdForm,
    EdAlert,
    EdInputFile,
  },
  mounted() {
    this.getArquivo();
  },
  created() {},
  data() {
    return {
      formData: null,
    };
  },
  watch: {
    'intId'(){
      this.getArquivo()
    }
  },
  computed: {},
  methods: {

    getArquivo() {
      if (!this.intId) return;

      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Common/Arquivo", { intId: this.intId })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.formData = Object.assign(objResponse.result, {});
          }
        });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.formData.intId = this.intId;

      this.$root.$request
        .post("Common/Arquivo", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.$emit("salvar", objResponse.result);
          }
        });
    },
  },
};
</script>
