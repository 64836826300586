<template>
  <div>
    <ed-modal v-model="bind.boolExibirCadastro" width="50%">
      <div slot="title">Novo Upload de Documento </div>

      <div class="" slot="content">
          <ed-upload :intId="formData.intId ? formData.intId : 0"  :key="$root.$session.versao" @salvar="getArquivo()"/>
      </div>
    </ed-modal>

    <ed-table
      :key="$root.$session.versao"
      strModel="Common/Arquivo"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @search="getArquivo"
    />
  </div>
</template>

<script>
import { EdTable, EdModal } from "@/components/common/form";
import EdUpload from "@/components/configuracoes/upload/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable, EdModal,EdUpload },
  mounted() {
    this.getArquivo();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      bind:{
        boolExibirCadastro:false
      },
      formData:{},
      arrayHeaders: [
        {
          text: "Nome",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Endereço",
          sortable: true,
          value: "strUrl",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros: {},
    };
  },
  methods: {
    onRegister() {
      this.bind.boolExibirCadastro = !this.bind.boolExibirCadastro 
    },

    onEdit(item){
      this.formData = Object.assign(item,{})
      this.bind.boolExibirCadastro = !this.bind.boolExibirCadastro 
    },

    getArquivo() {
      this.bind.boolExibirCadastro = false;
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Common/Arquivo", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objModel) => {

              let strImagem = ''

              if(objModel.strTipo.indexOf('image') != -1){
                strImagem = '<img width="100" heigth="100" src="'+objModel.strUrl+'"/><br>'
              }
              let item = {
                intId: objModel.intId,
                strNome: strImagem+objModel.strNome+' ('+this.$utilidade.toMb(objModel.intTamanho)+')',
                strUrl:objModel.strUrl,
                _item: objModel,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
